<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="setting">
    <div class="content" v-loading="loading">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="140px" size="medium"
        class="medium-form">
        <div class="modular">
          <h2 class="modular-title">审核设置</h2>
          <div class="modular-content">
            <el-form-item label="评论审核：" prop="comment_check">
              <el-radio-group v-model="form.comment_check">
                <el-radio :label="0">先发后审</el-radio>
                <el-radio :label="1">先审后发</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
<!--        <div class="modular">-->
<!--          <h2 class="modular-title">黑名单设置</h2>-->
<!--          <div class="modular-content">-->
<!--            <el-form-item label="黑名单自动移出：" prop="blacklist_duration">-->
<!--              <el-input v-model="form.blacklist_duration">-->
<!--                <template #append>天</template>-->
<!--              </el-input>-->
<!--              <div class="info">-->
<!--                <i class="el-icon-info"></i>设置用户被拉入黑名单后，自动移出黑名单的天数，为空，则不自动移出，需手动移出-->
<!--              </div>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--        </div>-->
        <div class="modular">
          <h2 class="modular-title">分享配置</h2>
          <div class="modular-content">
            <el-form-item label="分享标题：" prop="share_title">
              <el-input v-model="form.share_title"></el-input>
            </el-form-item>
            <el-form-item label="分享图片：" prop="share_image">
              <el-input v-show="false" v-model="form.share_image"></el-input>
              <ImageItem v-model="form.share_image" width="100px" height="100px" />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading"
        @click="onSave('ruleForm')">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import ImageItem from "@/modules/common/components/ImageItem.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { detail, save } from "../api/settings";
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        comment_check: 0, //评论审核 0: 先发后审，1:先审后发
        blacklist_duration: "", //多少天黑名单自动移出
        share_title: "", //分享标题
        share_image: "", //分享图片
      },
      rules: {
        blacklist_duration: [
          {
            type: "integer",
            min: 0,
            message: "请输入不小于0的整数",
            trigger: "blur",
            transform: (value) => Number(value),
          },
        ],
      },
      editData: {}, //编辑的数据
      saveLoading: false, //保存中
    };
  },
  methods: {
    //获取详情
    getDetail() {
      this.loading = true;
      detail()
        .then((res) => {
          const { data } = res;
          this.editData = data;
          this.initForm();

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.editData[key];
      });
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = { config: this.form };
          save(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);

              this.saveLoading = false;
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
  created() {
    this.getDetail();
  },
  components: {
    ImageItem,
    FixedActionBar,
  },
};
</script>

<style lang="scss" scoped>
</style>
