<template>
  <div class="options-image" :class="[(!hidePreview || !hideDelete)? 'cursor-pointer': '']"
       v-show="src" :style="{
    width: widthSize,
    height: heightSize,
    borderRadius: borderRadiusSize
  }" @click="handlePreview">
    <el-image class="options-image-src" :fit="fit" :src="fixImageUrl(src, {w: cutSize})">
      <div v-if="$slots.default" slot="error">
        <slot/>
      </div>
    </el-image>
    <div v-if="!(hidePreview && hideDelete) && !(!hidePreview && hideDelete)" class="options-image-mask">
      <p v-if="!hidePreview" class="options-image-icon el-icon-zoom-in" @click="preview"></p>
      <p v-if="!hideDelete" class="options-image-icon el-icon-delete" @click="$emit('delete')"></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "OptionsImage",
    props: {
      src: {
        type: String,
        required: true
      },
      width: {
        type: [Number, String],
        default: '100%',
      },
      height: {
        type: [Number, String],
        default: '100%',
      },
      borderRadius: {
        type: [Number, String],
        default: '4px'
      },
      fit: {
        type: String,
        default: 'contain'
      },
      hidePreview: {
        type: Boolean,
        default: false
      },
      hideDelete: {
        type: Boolean,
        default: false
      },
      previewSrcList: {
        type: Array,
        default: null
      },
      /* 自定义点击预览的处理 */
      customPreview: {
        type: Boolean,
        default: false
      },
      cutSize: {
        type: Number,
        default: 300
      }
    },
    data() {
      return {

      }
    },
    computed: {
      widthSize() {
        if (typeof this.width === "number") {
          return this.width + "px";
        } else {
          return this.width;
        }
      },
      heightSize() {
        if (typeof this.height === "number") {
          return this.height + "px";
        } else {
          return this.height;
        }
      },
      borderRadiusSize() {
        if (typeof this.borderRadius === "number") {
          return this.borderRadius + "px";
        } else {
          return this.borderRadius;
        }
      },
      // 从当前图片开始预览
      srcList() {
        if (this.previewSrcList && this.previewSrcList.length) {
          // 定位预览当前图片
          let index = this.previewSrcList.indexOf(this.src);
          return this.previewSrcList.slice(index).concat(this.previewSrcList.slice(0,index));
        }
        return this.previewSrcList || [this.src]
      }
    },
    methods: {
      handlePreview() {
        if (!this.hidePreview && this.hideDelete) {
          this.preview()
        } else {
          this.$emit('click')
        }
      },
      preview() {
        if (this.customPreview) {
          this.$emit('preview')
        } else {
          this.$previewImage(this.srcList)
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .options-image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f1f1f1;

    &:hover {
      .options-image-mask {
        opacity: 1;
      }
    }

    .options-image-src {
      width: 100%;
      height: 100%;
    }

    .options-image-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .6);
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      p {
        font-size: 14px;
        line-height: 30px;
        color: white;
        cursor: pointer;

        & + p {
          margin-left: 15px;
        }
      }
    }
  }
</style>
