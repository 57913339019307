<template>
  <!-- 仅点击 -->
  <div>
    <div
      class="add-button"
      :class="[disabled ? 'disabled' : '', direction]"
      :style="{
        width: widthSize,
        height: heightSize,
        borderRadius: borderRadiusSize,
        borderStyle: border ? borderStyle : 'none',
      }"
      @click="!disabled ? $emit('click', $event) : null"
    >
      <slot></slot>
      <template v-if="!$slots.default">
        <i class="add-button-icon" :class="[iconClass]"></i>
        <p class="add-button-text" v-if="text">{{ text }}</p>
      </template>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script>
export default {
  name: 'AddButton',
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    borderRadius: {
      type: [Number, String],
      default: '4px',
    },
    text: {
      type: String,
      default: '上传图片',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'vertical',
    },
    borderStyle: {
      type: String,
      default: 'dashed',
    },
    iconClass: {
      type: String,
      default: 'el-icon-plus',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    widthSize() {
      if (typeof this.width === 'number') {
        return this.width + 'px'
      } else {
        return this.width
      }
    },
    heightSize() {
      if (typeof this.height === 'number') {
        return this.height + 'px'
      } else {
        return this.height
      }
    },
    borderRadiusSize() {
      if (typeof this.borderRadius === 'number') {
        return this.borderRadius + 'px'
      } else {
        return this.borderRadius
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-button {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 1px dashed #dcdfe6;
  color: #757575;

  &:hover {
    border-color: #3576ff !important;
    color: #3576ff !important;
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      border-color: #dcdfe6 !important;
      color: #757575 !important;
    }
  }

  &.horizontal {
    flex-direction: row;

    .add-button-icon {
      font-size: 16px;
    }

    .add-button-text {
      margin-top: 0;
      margin-left: 5px;
    }
  }

  &.vertical {
    flex-direction: column;
  }

  .add-button-icon {
    font-size: 25px;
  }

  .add-button-text {
    font-size: 14px;
    line-height: 1;
    text-align: center;
    margin-top: 5px;
    margin-left: 0;
  }
}
</style>
