<template>
  <div class="image-item" :style="{
    width: widthSize,
    height: heightSize,
    borderRadius: borderRadiusSize
  }">
    <add-button class="image-item-add" :text="text" v-show="!defaultSrc && !value" @click="isAdd = true"></add-button>
    <div class="image-item-default" v-if="defaultSrc" v-show="!value" :style="{
      borderRadius: borderRadiusSize
    }">
      <el-image :src="defaultSrc" :fit="fit" @click="isAdd = true"></el-image>
    </div>
    <options-image class="image-item-options" v-if="value"
                   :src="value" :fit="fit" :preview-src-list="previewSrcList"
                   :hide-delete="hideDelete" :hide-preview="hidePreview"
                   @delete="$emit('input', '')"></options-image>
    <media-selector v-model="isAdd"
                    @select="$emit('input', $event[0].url)"></media-selector>
  </div>
</template>

<script>
  import AddButton from "../../../base/components/Base/AddButton";
  import OptionsImage from "../../../base/components/Media/OptionsImage";
  import MediaSelector from "./MediaSelector";
  export default {
    name: "ImageItem",
    components: {MediaSelector, OptionsImage, AddButton},
    props: {
      // v-model 图片资源地址
      value: {
        type: String,
        required: true
      },
      // 适用于默认头像之类的，不给删除，前端写死，后端不保存
      defaultSrc: {
        type: String
      },
      previewSrcList: {
        type: Array,
        default: null
      },
      width: {
        type: [Number, String],
        default: '100%',
      },
      height: {
        type: [Number, String],
        default: '100%',
      },
      borderRadius: {
        type: [Number, String],
        default: '4px'
      },
      fit: {
        type: String,
        default: 'contain'
      },
      hidePreview: {
        type: Boolean,
        default: false
      },
      hideDelete: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        default: "上传图片"
      }
    },
    data() {
      return {
        isAdd: false,
      }
    },
    computed: {
      widthSize() {
        if (typeof this.width === "number") {
          return this.width + "px";
        } else {
          return this.width;
        }
      },
      heightSize() {
        if (typeof this.height === "number") {
          return this.height + "px";
        } else {
          return this.height;
        }
      },
      borderRadiusSize() {
        if (typeof this.borderRadius === "number") {
          return this.borderRadius + "px";
        } else {
          return this.borderRadius;
        }
      },
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .image-item {
    display: inline-block;

    .image-item-default {
      overflow: hidden;
      width: 100%;
      height: 100%;
      cursor: pointer;
      border: 1px dashed #fff;

      &:hover {
        border-color: #3576ff;
      }

      .el-image {
        width: 100%;
        height: 100%;
      }
    }
  }
  .image-item-add {
    height: 100%;
  }
</style>
