/*
 * @Author: mulingyuer
 * @Date: 2021-09-02 18:09:08
 * @LastEditTime: 2021-09-02 18:10:10
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\settings.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//详情
export const detail = data => {
  return api({
    url: "/admin/classroom/settings/getConfig",
    method: "post",
    data
  })
}

//保存
export const save = data => {
  return api({
    url: "/admin/classroom/settings/setConfig",
    method: "post",
    data
  })
}